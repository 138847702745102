html,
body {
  margin: 0;
  padding: 0;
  line-height: normal; }

p {
  margin: 0; }

.page-container {
  max-width: 1200px; }

.region {
  position: relative; }

.region::before {
  display: block;
  content: "";
  width: 100%; }

.infinite-row .nobasis {
  flex-basis: 0; }

/*
    padding-top in percentages refers to parent width
    this allows us to specify an aspect-ratio
*/
.region_landscape-large::before {
  padding-top: calc(400 / 1024 * 100%); }

.region_square::before {
  padding-top: calc(1 / 1 * 100%); }

.region_landscape-small::before {
  padding-top: calc(1 / 2 * 100%); }

.region_portrait-small::before {
  padding-top: calc(2 / 1 * 100%); }

div.sf-interactions-proxyHoverContainer a {
  overflow: visible; }
